<template>
    <div>
        {{ data.date | userTime}}
    </div>

</template>

<script>
export default {
    name: "dateTemplate",
    data: function() {
        return {
            data: {},
        }
    },
    methods: { },
    computed: { }
}
</script>

<style scoped>

</style>
