<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-linux"></i> Time on site and bounce rate metrics</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-inline">
                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.date" class="form-control date" placeholder="Date"/>
                                </div>
                                <div class="form-group mr-1 mt-3 p-1">
                                    <p>&#9866;</p>
                                </div>
                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                                </div>
                                <div class="form-group mr-1">
                                    <input type="text" v-model="filter.domain" class="form-control" placeholder="Domain"/>
                                </div>
                                <div class="form-group mr-1  pl-2">
                                    <select class="form-control" v-model="filter.period">
                                        <option selected :value="Hourly"> Hourly </option>
                                        <option v-for="item in period" :key="item" :value="item">{{item}}</option>
                                    </select>
                                </div>
                                <!--                            <div class="form-group mr-1">-->
                                <!--                                <select v-model="filter.time" class="form-control">-->
                                <!--                                    <option value="" selected>&#45;&#45; Any Time &#45;&#45;</option>-->
                                <!--                                    <option v-for="time in timeSelector" :key="time" :value="time">{{time}}</option>-->
                                <!--                                </select>-->
                                <!--                            </div>-->

                                <ejs-progressbutton ref="progressBtn" style="color: white; background-color: #383838;" content="SEARCH" :duration="10000" :spinSettings="spinSettings" v-on:click.native="search(true)"></ejs-progressbutton>
                                <ejs-button ref="progressBtn" style="color: white; background-color: #383838; margin-left: 10px;" content="Export" :duration="10000" :spinSettings="spinSettings" v-on:click.native="exportToExcel()"></ejs-button>
                            </div>
                        </div>
                    </div>
                    <hr/>

                    <div class="control-section">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-sm"></div>
                                <div class="col-sm"></div>
                                <div class="col-sm"></div>
                                <div class="col-sm"></div>
                                <div class="col-sm"><strong>Total</strong></div>
                                <div class="col-sm"><strong>{{ total }}</strong></div>
                            </div>
                            <ejs-grid ref='grid' id='overviewgrid' :dataSource="metrics" :filterSettings='filterOptions' :allowSorting='true'
                                      :allowExcelExport='true' height='auto' rowHeight=38 :enableHover='false'
                                      :allowPaging="true" :pageSettings='pageSettings' :actionBegin='actionBegin'>
                                <e-columns>
                                    <e-column field='dateString' headerText='Date' width='200' :filter='filter'></e-column>
                                    <e-column field='hour' headerText='Hour' width='200' :filter='filter'></e-column>
                                    <e-column field='url' headerText='Domain' width='200' :filter='filter'></e-column>
                                    <e-column field='tos' headerText='Time on Site (h:m:s)' width='200' :filter='filter'></e-column>
                                    <e-column field='bounce_rate' headerText='Bounce rate' width='200' :filter='filter'></e-column>
                                    <e-column field='sessions' headerText='Total Sessions' width='200' :filter='filter'></e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                    </div>
                </div>
            </div>
            <ejs-toast ref='elementRef' id='element' title='' content='' :position='position'></ejs-toast>
        </div>


    </div>
</template>

<script>
import {GridPlugin, Page, Sort, ExcelExport, Toolbar, Group, Filter, Resize} from '@syncfusion/ej2-vue-grids';
import { ToastPlugin } from "@syncfusion/ej2-vue-notifications";
import Vue from 'vue';
import { ProgressButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
import { enableRipple } from '@syncfusion/ej2-base';
import datetempVue from "../components/dateTemplate";
import swal from "sweetalert2"
import moment from 'moment-timezone'

enableRipple(true);
Vue.use(ProgressButtonPlugin);
Vue.use(GridPlugin);
Vue.use(ToastPlugin);

import axios from "axios";

export default {
    name: 'Metrics',
    props:['user'],
    components: {},
    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter,
            Resize,
            Toolbar
        ]
    },

    data: function () {
        return {
            dateTemplate: function () {
                return { template : datetempVue}
            },
            total: 0,
            metrics: [],
            filterOptions: {
                type: 'Menu'
            },
            filter: {
                type: 'CheckBox',
                date: new Date().toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),
                domain: ""
            },
            filters: {
                campaign_id: ''
            },
            page: 0,
            count: true,
            toolbar: ['ExcelExport'],
            pageSettings: { pageSize: 25 },
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
            position: { X: 'Right', Y: 'Bottom' },
            period:[ 'Daily'],

        }
    },
    async created (){
        this.$root.preloader = false;
        // this.load();
        this.search();
        window.addEventListener("keydown", this.windowListener);
    },


    computed: {},
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13){
                this.search(true);
            }
        },
        toolbarClick: function () {
            this.$refs.overviewgrid.excelExport();
        },
        load: function() {
            let proxy = this;
            this.$refs.overviewgrid.$el.ej2_instances[0].on('data-ready', function () {
                proxy.dReady =  true;
            })
            this.$refs.overviewgrid.$el.addEventListener('DOMSubtreeModified', function () {
                if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
                    let e = performance.now() - proxy.stTime;
                    proxy.loadTime = "Load Time: <b>" + e.toFixed(0) + "</b><b>ms</b>";
                    proxy.stTime = null;
                    proxy.dReady = false;
                    proxy.isDataChanged = false;
                    proxy.$refs.msgelement.classList.remove('e-hide');
                }
            })
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        },
        getTimeFrame: function(dateTime){
            const timeFrameMap = [
                '12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'
            ];

            const timeFrameMapUTC = [
                '00 - 01',
                '01 - 02',
                '02 - 03',
                '03 - 04',
                '05 - 06',
                '06 - 07',
                '07 - 08',
                '09 - 10',
                '10 - 11',
                '12 - 13',
                '13 - 14',
                '14 - 15',
                '15 - 16',
                '16 - 17',
                '17 - 18',
                '18 - 19',
                '19 - 20',
                '20 - 21',
                '21 - 22',
                '22 - 23',
                '23 - 24'
            ];


            // let d = new Date(dateTime);
            if(this.user.timezone == 'UTC')
                return timeFrameMapUTC[dateTime];
            else
                return timeFrameMap[dateTime];

        },
        actionBegin: function(args) {
            console.log(args);
            if(args.requestType === "paging") {
                this.page = args.currentPage;
                console.log(this.page)
                this.search(false);
            }
        },
        search: function(count){
            let request = {max: this.pageSettings.pageSize, user:{timezone:this.user.timezone}, page: this.page, filters:{report:false}};

            if(this.filter.date) request.filters.date = this.filter.date;
            if(this.filter.to) request.filters.to = this.filter.to;
            if(this.filter.domain) request.filters.domain = this.filter.domain;
            if(this.filter.period) request.filters.period = this.filter.period;


            this.metrics = {result: [], count: 0};
            request.count = count;

            axios.get(`${this.$root.serverUrl}/admin/reports/metrics`, {params:request}).then((resp) => {
                let count = (resp.data) ? resp.data.count : 0;
                let metrics = (resp.data) ? resp.data.data : [];
                this.total = 0

                if(this.filter.period == 'Daily'){
                    metrics = metrics.map((metric) => {
                        metric.hour = '24 Hour';
                        metric.dateString = moment(metric.date).tz(this.user.timezone).format('MM-DD-YYYY');
                        metric.tos = this.format(metric.tos);
                        metric.bounce_rate = metric.bounce_rate.toFixed(2) + '%';
                        this.total += metric.sessions;

                        return metric
                    })

                }else{
                    metrics = metrics.map((metric) => {
                        metric.hour = new Date(metric.date).getHours()
                        metric.hour = this.getTimeFrame(metric.hour);
                        metric.dateString = moment(metric.date).tz(this.user.timezone).format('MM-DD-YYYY');
                        metric.tos = this.format(metric.tos);
                        this.total += metric.sessions;

                        return metric
                    })
                }

                // metrics.sort((a, b) => (a.index > b.index) ? -1 : 1)
                this.metrics = {count: count, result: metrics}
            }).catch(() => {
                this.$refs.elementRef.show( { title: 'Error !', content: 'A problem has been occurred while fetching your data.', cssClass: 'e-toast-danger' });
                this.$refs.progressBtn.progressComplete()
            });
        },

        format(time) {
            var hrs = Math.floor(time / 3600);
            var mins = Math.floor((time % 3600) / 60);
            var secs = Math.floor(time) % 60;

            var ret = "";
            if (hrs > 0) {
                ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
            } else {
                ret += "00" + ":" + (mins < 10 ? "0" : "");
            }
            ret += "" + mins + ":" + (secs < 10 ? "0" : "");
            ret += "" + secs;
            return ret;
        },


        exportToExcel: async function() {
            let request = {max: 50000, page: 1,filters:{report:true}};
            let metrics = [];

            if(this.filter.date) request.filters.date = this.filter.date;
            if(this.filter.to) request.filters.to = this.filter.to;
            if(this.filter.domain) request.filters.domain = this.filter.domain;
            if(this.filter.period) request.filters.period = this.filter.period;


            swal.fire('Notice', 'Data export processing', 'info')
            axios.get(`${this.$root.serverUrl}/admin/reports/metrics`, {params:request}).then((resp) => {

                let count = (resp.data) ? resp.data.count : 0;
                metrics = (resp.data) ? resp.data.data : [];
                this.total = 0

                if(this.filter.period == 'Daily'){
                    metrics = metrics.map((metric) => {
                        metric.hour = "24 Hour"
                        metric.dateString = moment(metric.date).tz(this.user.timezone).format('MM-DD-YYYY');
                        metric.bounce_rate = metric.bounce_rate.toFixed(2) +' %'
                        metric.tos = this.format(metric.tos);
                        this.total += metric.sessions;
                        return metric
                    })

                }  else{
                    metrics = metrics.map((metric) => {
                        metric.hour = new Date(metric.date).getHours()
                        metric.hour = this.getTimeFrame(metric.date);
                        metric.tos = this.format(metric.tos);
                        metric.dateString = moment(metric.date).tz(this.user.timezone).format('MM-DD-YYYY');
                        this.total += metric.sessions;
                        return metric
                    })
                }
                metrics.sort((a, b) => (a.index > b.index) ? -1 : 1)
                metrics = {count: count, result: metrics}

                let fileName = `Metrics Export ${this.filter.date}`;
                fileName = fileName.replace(/ /g,"_");
                fileName = fileName.replace(/:/g,"_");
                fileName += ".xlsx";
                let excelExportProperties = {
                    fileName,
                    dataSource:metrics.result,
                    includeHiddenColumn: true,
                    multipleExport: { type: 'NewSheet' }
                };
                this.$refs.grid.excelExport(excelExportProperties);

            });



        },
    },
}
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-vue-notifications/styles/material.css';
.loading-feild {
    position: relative;
}
.loading-feild img {
    position: absolute;
    left: -26px;
    top: -31px;
}

.loading-feild select {
    padding: 10px;
}
@keyframes custom-rolling {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.template {
    border: 2px solid green;
    border-style: dotted;
    border-radius: 50%;
    border-top-color: transparent;
    border-bottom-color: transparent;
    height: 16px;
    width: 16px;
}
.template {
    -webkit-animation: custom-rolling 1.3s linear infinite;
    animation: custom-rolling 1.3s linear infinite;
}
#overviewgrid_excelexport {
    background-color: #28a745 !important;
    color: white !important;
}
.e-btn-icon.e-excelexport.e-icons.e-icon-left {
    color: white !important;
}
</style>
