import { render, staticRenderFns } from "./dateTemplate.vue?vue&type=template&id=244c27e4&scoped=true&"
import script from "./dateTemplate.vue?vue&type=script&lang=js&"
export * from "./dateTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "244c27e4",
  null
  
)

export default component.exports